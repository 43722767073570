<template>
  <div class="sgf-app-shell-atom" v-sgf-theme>
    <svg width="0" height="0" style="display: none">
      <symbol id="warning" viewBox="0 0 32 32">
        <path
          d="M12.2 4.6 2 22.4C.3 25.3 2.4 29 5.8 29h20.6c3.4 0 5.5-3.6 3.8-6.6L19.8 4.6c-1.7-2.9-5.9-2.9-7.6 0z"
          fill="var(--sgf-c-fill)"
          stroke="currentColor"
          stroke-width="2.8"
        />
        <path fill="none" stroke="currentColor" stroke-width="2.8" stroke-linecap="round" d="M16 10v8.6" />
        <circle fill="currentColor" stroke="currentColor" cx="15.9" cy="23.8" r="1.4" />
      </symbol>
      <symbol id="info" viewBox="0 0 32 32">
        <circle cx="16.2" cy="16" r="14.6" fill="var(--sgf-c-fill)" stroke="currentColor" stroke-width="2.8" stroke-miterlimit="10" />
        <path fill="none" stroke="currentColor" stroke-width="2.8" stroke-linecap="round" d="M15.9 7.8v10.4" />
        <circle cx="15.9" cy="24.1" r="1.4" fill="currentColor" stroke="currentColor" />
      </symbol>
      <symbol id="arrow-down" viewBox="0 0 32 32">
        <path fill="none" stroke="currentColor" stroke-width="2.8" stroke-linecap="round" d="m10.4 12.8 5.6 5.6 5.5-5.6" />
      </symbol>
      <symbol id="loader-primary" viewBox="0 0 46 46">
        <g fill="none" stroke="currentColor" stroke-width="5">
          <path stroke-linecap="round" d="M 23 3 A 20 20 45 0 1 43 23" />
          <path stroke-linecap="round" d="M 23 43 A 20 20 45 0 1 3 23" />
        </g>
      </symbol>
      <symbol id="cross" viewBox="0 0 32 32">
        <path style="fill: none; stroke: currentColor; stroke-width: 2.8; stroke-linecap: round" d="m1.4 1.4 29.2 29.2M30.6 1.4 1.4 30.6" />
      </symbol>
      <symbol id="circle" viewBox="0 0 32 32">
        <path stroke="currentColor" d="M16 26c-5.525 0-10-4.475-10-10S10.475 6 16 6s10 4.475 10 10-4.475 10-10 10z" />
      </symbol>
    </svg>
    <ApiAtom :isLoading="loader !== undefined" :header="loader" :error="error" @dismissError="$emit('dismissError')">
      <div class="sgf-app-shell-atom__layout">
        <slot></slot>
      </div>
    </ApiAtom>
  </div>
</template>

<script>
import ApiAtom from '../atoms/ApiAtom.vue';
export default {
  components: {
    ApiAtom,
  },
  props: {
    configuration: Object,
    error: String,
    loader: String,
  },
  mounted() {
    let r = document.querySelector(':root');
    let { backgroundColor, textColor, primaryColor, secondaryColor, primaryGrayColor, secondaryGrayColor, statusRedColor, statusPinkColor } =
      this.configuration;
    r.style.setProperty('--sgf-c-background', backgroundColor ? backgroundColor : '#ffffff');
    r.style.setProperty('--sgf-c-text', textColor ? textColor : '#303030');
    r.style.setProperty('--sgf-c-primary', primaryColor ? primaryColor : '#9dd3ef');
    r.style.setProperty('--sgf-c-secondary', secondaryColor ? secondaryColor : '#e1eff7');
    r.style.setProperty('--sgf-c-primary-gray', primaryGrayColor ? primaryGrayColor : '#6c6c6c');
    r.style.setProperty('--sgf-c-secondary-gray', secondaryGrayColor ? secondaryGrayColor : '#f4f4f4');
    r.style.setProperty('--sgf-c-status-red', statusRedColor ? statusRedColor : '#ce0000');
    r.style.setProperty('--sgf-c-status-pink', statusPinkColor ? statusPinkColor : '#fdf0f0');
  },
};
</script>
<style>
:root {
  --sgf-c-background: #ffffff;
  --sgf-c-text: #303030;
  --sgf-c-primary: #9dd3ef;
  --sgf-c-secondary: #e1eff7;
  --sgf-c-primary-gray: #6c6c6c;
  --sgf-c-secondary-gray: #f4f4f4;
  --sgf-c-status-pink: #fdf0f0;
  --sgf-c-status-red: #ce0000;
  --sgf-c-status-yellow: #fcf1c3;
  --sgf-c-status-brown: #765001;
  --sgf-c-text-blue: #4d74a2;
}

.sgf-ace div,
.sgf-ace span,
.sgf-ace h1,
.sgf-ace h2,
.sgf-ace h3,
.sgf-ace h4,
.sgf-ace h5,
.sgf-ace h6,
.sgf-ace p,
.sgf-ace pre,
.sgf-ace a,
.sgf-ace img,
.sgf-ace strong,
.sgf-ace sub,
.sgf-ace sup,
.sgf-ace var,
.sgf-ace ol,
.sgf-ace ul,
.sgf-ace li,
.sgf-ace fieldset,
.sgf-ace form,
.sgf-ace label,
.sgf-ace legend,
.sgf-ace table,
.sgf-ace caption,
.sgf-ace tbody,
.sgf-ace tfoot,
.sgf-ace thead,
.sgf-ace tr,
.sgf-ace th,
.sgf-ace td,
.sgf-ace article,
.sgf-ace footer,
.sgf-ace header,
.sgf-ace time {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.sgf-ace table {
  border-collapse: collapse;
  border-spacing: 0;
}
.sgf-ace :deep(svg) {
  box-sizing: content-box;
}
html body .sgf-ace {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.7em;
  color: var(--sgf-c-text);
}

html body .sgf-ace input,
html body .sgf-ace select {
  font-size: 16px;
}

.sgf-ace .scroll::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #dddddd;
  background-clip: content-box;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--sgf-c-text);
}
.scroll::-webkit-scrollbar-thumb:window-inactive {
  border-radius: 8px;
  background-color: var(--sgf-c-text);
}
.sgf-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  display: block;
  white-space: nowrap;
}
.sgf-ace .sgf-shadow {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
</style>
<style scoped>
.sgf-ace.sgf-app-shell-atom {
  background: var(--sgf-c-background);
}

.sgf-ace .sgf-app-shell-atom__layout {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 16px;
}
</style>
