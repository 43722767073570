export default {
  getters: {
    VALIDATION: 'validation',
  },
  actions: {
    INITIALIZE: 'initialize',
    SET_FIELD: 'setField',
    SET_PERSON_FIELD: 'setPersonField',
    SUBMIT: 'submit',
    SUBMIT_NEW_MEMBER: 'submitNewMember',
    SUBMIT_VERIFICATION: 'submitVerification',
    SUBMIT_EXISTING_GOLF_ID: 'submitExistingGolfId',
    CLEANUP: 'cleanup',
    SET_ERROR: 'setError',
  },
  mutations: {
    SET_FIELD: 'setField',
    SET_PERSON_FIELD: 'setPersonField',
    CLEANUP: 'cleanup',
    CLEANUP_PERSON: 'cleanupPerson',
  },
};
