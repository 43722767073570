<template>
  <table class="table-atom" v-sgf-theme>
    <thead>
      <tr>
        <th v-for="col in columns" :key="col.key" :style="'text-align:' + col.align">{{ col.header }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, rowIndex) in data" :key="rowIndex">
        <td v-for="col in columns" :key="rowIndex + col.key" :style="'text-align:' + col.align">
          <slot :name="col.key">
            {{ row[col.key] }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    columns: Array,
    data: Array,
  },
};
</script>

<style scoped>
table {
  width: 100%;
}
table,
thead,
tbody,
tfoot,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: none;
  border-collapse: inherit;
  border-spacing: 0;
  border-color: inherit;
  vertical-align: inherit;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
}
th,
td {
  width: auto;
}

.sgf-ace tbody tr:nth-child(odd) {
  background: var(--sgf-c-secondary);
}

.sgf-ace td {
  padding: 4px 4px;
}

.sgf-ace th {
  font-weight: bold;
  padding: 0px 4px;
  text-align: left;
  font-size: 10px;
  border-bottom: 2px solid var(--bta-c-background);
}
</style>
