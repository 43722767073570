<template>
  <div class="tab-atom" v-sgf-theme>
    <button
      class="tab-atom__tab"
      v-for="(tab, index) in tabs"
      :key="index"
      :class="{ 'tab-atom__tab--selected': tab.value === value }"
      @click="$emit('input', tab.value)"
    >
      {{ tab.label }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    tabs: Array,
    value: String,
  },
  emits: ['input'],
};
</script>

<style scoped>
.sgf-ace.tab-atom {
}
.sgf-ace .tab-atom__tab {
  color: var(--sgf-c-text);
  padding: 8px 16px 0 0;
  margin: 0;
  border: 0;
  border-bottom: 2px solid var(--sgf-c-secondary-gray);
  border-radius: 0;
  background: transparent;
  cursor: pointer;
}

.sgf-ace .tab-atom__tab:hover:not(.tab-atom__tab--selected) {
  border-color: var(--sgf-c-primary-gray);
}

.sgf-ace .tab-atom__tab--selected {
  border-bottom: 2px solid var(--sgf-c-text);
  font-weight: bold;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -4px;
}
</style>
