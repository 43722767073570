<template>
  <BoxAtom label="Vårdnadshavare">
    <div class="caregiver-frame" v-sgf-theme>
      <TabAtom :tabs="tabs" :value="selectedCaregiver" @input="setField({ field: 'selectedCaregiver', value: $event })" />
      <PersonView personType="caregiver1" v-if="selectedCaregiver === 'caregiver1'" />
      <PersonView personType="caregiver2" v-if="selectedCaregiver === 'caregiver2'" />
    </div>
  </BoxAtom>
</template>

<script>
import types from '../../store/types';
import { mapState, mapActions } from 'vuex';
import TabAtom from '../../../../widget-common/src/components/atoms/TabAtom.vue';
import BoxAtom from '../../../../widget-common/src/components/atoms/BoxAtom.vue';
import PersonView from '../views/PersonView.vue';
export default {
  components: {
    TabAtom,
    PersonView,
    BoxAtom,
  },
  computed: {
    ...mapState(['selectedCaregiver']),
    tabs() {
      return [
        {
          label: 'Vårdnadshavare 1',
          value: 'caregiver1',
        },
        {
          label: 'Vårdnadshavare 2',
          value: 'caregiver2',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      setField: types.actions.SET_FIELD,
    }),
  },
};
</script>

<style scoped>
.sgf-ace.caregiver-frame {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 16px;
}
</style>
