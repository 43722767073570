function themeDirectives(app, config) {
  app.directive('sgf-theme', {
    mounted(el) {
      if (config.theme === 'ace') {
        el.classList.add('sgf-ace');
      }
    },
  });
}

export default themeDirectives;
