import ssnHelper from '../../../widget-common/src/helpers/ssnHelper';
import personHelper from '../helpers/personHelper';
import types from './types';
function getError(person, value, field) {
  return { type: 'error', value: value, field: field, personType: person };
}
function getRequiredError(person, field, fieldName) {
  return getError(person, `Fältet "${fieldName}" är obligatoriskt`, field);
}
function isRequired(person, field, fieldName, state, result) {
  if (!state.persons[person][field]) {
    result.push(getRequiredError(person, field, fieldName));
  }
}

function isPhoneNumberValid(mobile) {
  const phoneNumberRegex = /^((\+46))[\s-]?7[02369][\s-]?\d{4}[\s-]?\d{3}$/gm;
  if (phoneNumberRegex.test(mobile)) {
    return true;
  } else {
    return false;
  }
}

function isEmailValid(email) {
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/gm;
  if (emailRegex.test(email)) {
    return true;
  } else {
    return false;
  }
}

function validateMember(state, result) {
  let person = state.persons['member'];
  if (!person.hasGolfId) {
    isRequired('member', 'ssn', 'Personnummer', state, result);
    isRequired('member', 'firstName', 'Förnamn', state, result);
    isRequired('member', 'lastName', 'Efternamn', state, result);
    isRequired('member', 'address', 'Gatuadress', state, result);
    isRequired('member', 'postalCode', 'Postnummer', state, result);
    isRequired('member', 'postalAddress', 'Postort', state, result);
    //isRequired('member', 'phone', 'Telefon', state, result);
    isRequired('member', 'mobile', 'Mobil', state, result);
    isRequired('member', 'email', 'E-post', state, result);
    isRequired('member', 'confirmEmail', 'Bekräfta E-post', state, result);

    if (!isPhoneNumberValid(person.mobile)) {
      result.push(getError('member', 'Mobilnummer måste börja med +46 och vara giltig', 'mobile'));
    }

    if (!isEmailValid(person.email)) {
      result.push(getError('member', 'Ogiltig E-post', 'email'));
    }

    if (!isEmailValid(person.confirmEmail) && isEmailValid) {
      result.push(getError('member', 'Ogiltig E-post', 'confirmEmail'));
    }

    if (person.email !== person.confirmEmail && isEmailValid) {
      result.push(getError('member', 'Epost måste vara samma', 'confirmEmail'));
    }
    if (!ssnHelper.validateSocialSecurityNumber(person.ssn)) {
      result.push(getError('member', 'Ogiltigt personnummer', 'ssn'));
    }
  } else {
    isRequired('member', 'golfId', 'Golf-ID', state, result);
    isRequired('member', 'verificationEmail', 'E-post', state, result);
    if (!isEmailValid(person.verificationEmail)) {
      result.push(getError('member', 'Ogiltig e-post', 'verificationEmail'));
    }
  }

  if (!state.selectedMembership) {
    result.push(getRequiredError('member', 'selectedMembership', 'Välj en medlemskategori'));
  }
}

function validateCaregiver(personType, state, result) {
  let person = state.persons[personType];
  if (!person.hasGolfId) {
    isRequired(personType, 'ssn', 'Personnummer', state, result);
    isRequired(personType, 'firstName', 'Förnamn', state, result);
    isRequired(personType, 'lastName', 'Efternamn', state, result);
    isRequired(personType, 'address', 'Gatuadress', state, result);
    isRequired(personType, 'postalCode', 'Postnummer', state, result);
    isRequired(personType, 'postalAddress', 'Postort', state, result);
    //isRequired('member', 'phone', 'Telefon', state, result);
    isRequired(personType, 'mobile', 'Mobil', state, result);
    isRequired(personType, 'email', 'E-post', state, result);
    isRequired(personType, 'confirmEmail', 'Bekräfta E-post', state, result);

    if (!isPhoneNumberValid(person.mobile)) {
      result.push(getError(personType, 'Mobilnummer måste börja med +46 och vara giltig', 'mobile'));
    }

    if (!isEmailValid(person.email)) {
      result.push(getError(personType, 'Ogiltig E-post', 'email'));
    }

    if (!isEmailValid(person.confirmEmail) && isEmailValid) {
      result.push(getError(personType, 'Ogiltig E-post', 'confirmEmail'));
    }

    if (person.email !== person.confirmEmail && isEmailValid) {
      result.push(getError(personType, 'E-post måste vara samma', 'confirmEmail'));
    }
    if (!ssnHelper.validateSocialSecurityNumber(person.ssn)) {
      result.push(getError(personType, 'Ogiltigt personnummer', 'ssn'));
    }
  } else {
    isRequired(personType, 'golfId', 'Golf-ID', state, result);
  }
}

export default {
  [types.getters.VALIDATION]: (state) => {
    let result = [];
    if (!state.hasSubmitted) {
      return result;
    }
    validateMember(state, result);
    if (personHelper.hasData(state.persons.caregiver1)) {
      validateCaregiver('caregiver1', state, result);
    }
    if (personHelper.hasData(state.persons.caregiver2)) {
      validateCaregiver('caregiver2', state, result);
    }
    return result;
  },
};
