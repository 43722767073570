<template>
  <div class="validation-atom sgf-clamp" :class="validationClass" v-sgf-theme>
    <div v-if="!number" class="validation-atom__icon" :class="iconClass">
      <svg v-if="!isError">
        <use xlink:href="#warning"></use>
      </svg>
      <svg v-if="isError">
        <use xlink:href="#info"></use>
      </svg>
    </div>
    <div class="validation-atom__value sgf-clamp" :class="valueClass" :title="value">
      <span v-if="number">{{ number }}. </span>{{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    type: {
      type: String,
      default: 'error',
    },
    number: Number,
  },
  computed: {
    validationClass() {
      return {
        'validation-atom--has-number': this.number !== undefined,
      };
    },
    iconClass() {
      return {
        ['validation-atom__icon--' + this.type]: true,
      };
    },
    valueClass() {
      return {
        ['validation-atom__value--' + this.type]: true,
        'validation-atom__value--has-number': this.number !== undefined,
      };
    },
    isError() {
      if (this.type === 'error') {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.sgf-ace.validation-atom {
  display: flex;
  gap: var(--ace-gutter);
  align-items: center;
}

.sgf-ace .validation-atom__icon,
.sgf-ace .validation-atom__anchor {
  display: flex;
  align-items: center;
}

.sgf-ace .validation-atom__icon :deep(svg) {
  width: 16px;
  height: 16px;
}
.sgf-ace .validation-atom__icon--error {
  color: var(--sgf-c-status-red);
  --sgf-c-fill: var(--sgf-c-status-pink);
}
.sgf-ace .validation-atom__icon--warning {
  color: var(--sgf-c-status-brown);
  --sgf-c-fill: var(--sgf-c-status-yellow);
}
.sgf-ace .validation-atom__value--error {
  color: var(--sgf-c-status-red);
}
.sgf-ace .validation-atom__value--warning {
  color: var(--sgf-c-status-brown);
}
.sgf-ace .validation-atom__anchor {
  cursor: pointer;
}

.sgf-ace .validation-atom__anchor :deep(svg) {
  width: 16px;
  height: 16px;
}
.sgf-ace .validation-atom__anchor--error {
  color: var(--sgf-c-status-red);
  --sgf-c-fill: var(--sgf-c-status-pink);
}
.sgf-ace .validation-atom__anchor--warning {
  color: var(--sgf-c-status-brown);
  --sgf-c-fill: var(--sgf-c-status-yellow);
}
</style>
