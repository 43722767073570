import personHelper from './personHelper';

function getPersonPayload(person, isCaregiver) {
  let golfId = isCaregiver || person.hasGolfId ? person.golfId : undefined;
  return {
    GolfID: golfId,
    SocialSecurityNumber: person.ssn,
    FName: person.firstName,
    LName: person.lastName,
    Address: person.address,
    PostalCode: person.postalCode,
    PostalAddress: person.postalAddress,
    Phone: person.phone,
    Phone_Cell: person.mobile,
    Email: person.email,
    Email_Confirmation: person.confirmEmail,
  };
}

function registrationPayload(state) {
  let result = {
    OrganizationalUnitID: state.configuration.clubId,
    MembershipTypeID: state.selectedMembership,
    RecaptchaToken: state.recaptchaToken,
  };
  if (state.persons.member.hasGolfId === true && state.persons.member.golfId) {
    result.VerificationCode = state.persons.member.verificationCode;
  }
  result.Member = getPersonPayload(state.persons.member);
  if (state.isMinor) {
    if (personHelper.hasData(state.persons.caregiver1)) {
      result.Caregiver1 = getPersonPayload(state.persons.caregiver1);
    }
    if (personHelper.hasData(state.persons.caregiver2)) {
      result.Caregiver2 = getPersonPayload(state.persons.caregiver2);
    }
  }

  return result;
}

export default {
  registrationPayload: registrationPayload,
};
