export default {
  hasSubmitted: false,
  configuration: undefined,
  apiRoot: undefined,
  selectedMembership: '',
  error: undefined,
  loader: undefined,
  registered: false,
  memberships: undefined,
  isMinor: false,
  selectedCaregiver: 'caregiver1',
  recaptchaToken: undefined,
  persons: {
    member: {
      hasGolfId: false,
      ssn: '',
      firstName: '',
      lastName: '',
      address: '',
      postalCode: '',
      postalAddress: '',
      phone: '',
      mobile: '',
      email: '',
      confirmEmail: '',
      golfId: '',
      verificationEmail: '',
      verificationEmailSent: false,
      verificationCode: '',
    },
    caregiver1: {
      hasGolfId: false,
      ssn: '',
      firstName: '',
      lastName: '',
      address: '',
      postalCode: '',
      postalAddress: '',
      phone: '',
      mobile: '',
      email: '',
      confirmEmail: '',
      golfId: '',
    },
    caregiver2: {
      hasGolfId: false,
      ssn: '',
      firstName: '',
      lastName: '',
      address: '',
      postalCode: '',
      postalAddress: '',
      phone: '',
      mobile: '',
      email: '',
      confirmEmail: '',
      golfId: '',
    },
  },
};
