<template>
  <AppShellMolecule :configuration="configuration" :style="appShellMoleculeStyle" :loader="loader" :error="error" @dismissError="onDismissError">
    <PersonFrame person="member" v-if="!registered" />
    <MembershipView v-if="!registered" />
    <CaregiverFrame v-if="isMinor && !registered" />
    <FooterView v-if="!registered" />
    <CompletionView v-if="registered" />
  </AppShellMolecule>
</template>

<script>
import AppShellMolecule from '../../widget-common/src/components/molecules/AppShellMolecule.vue';
import PersonFrame from './components/frames/PersonFrame.vue';
import MembershipView from './components/views/MembershipView.vue';
import CaregiverFrame from './components/frames/CaregiverFrame.vue';
import FooterView from './components/views/FooterView.vue';
import CompletionView from './components/views/CompletionView.vue';
import types from './store/types';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    AppShellMolecule,
    MembershipView,
    FooterView,
    CompletionView,
    CaregiverFrame,
    PersonFrame,
  },
  computed: {
    ...mapState(['error', 'loader', 'registered', 'isMinor']),
    configuration() {
      return this.$widgetConfiguration;
    },
    appShellMoleculeStyle() {
      let { width } = this.configuration;
      return {
        width: width ? width : 'auto',
      };
    },
  },
  methods: {
    ...mapActions({
      initialize: types.actions.INITIALIZE,
      setField: types.actions.SET_FIELD,
    }),
    onDismissError() {
      this.setField({ field: 'error', value: undefined });
    },
  },
  mounted() {
    this.initialize(this.configuration);
  },
};
</script>

<style scoped></style>
