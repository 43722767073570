<template>
  <div class="completion-view" v-sgf-theme>
    <div class="completion-view__toast">Tack för din ansökan!</div>
    <ButtonAtom label="Registrera igen" @click="cleanup" />
  </div>
</template>

<script>
import types from '../../store/types';
import { mapActions } from 'vuex';
import ButtonAtom from '../../../../widget-common/src/components/atoms/ButtonAtom.vue';
export default {
  components: {
    ButtonAtom,
  },
  methods: {
    ...mapActions({
      cleanup: types.actions.CLEANUP,
    }),
  },
};
</script>

<style scoped>
.sgf-ace.completion-view {
  text-align: center;
}

.sgf-ace .completion-view__toast {
  font-size: 2em;
  font-weight: bold;
  padding-bottom: 25px;
}
</style>
