export default {
  hasData: (person) => {
    let fields = ['golfId', 'ssn', 'firstName', 'lastName', 'address', 'postalCode', 'postalAddress', 'phone', 'mobile', 'email', 'confirmEmail'];
    let result = false;
    fields.forEach((f) => {
      if (person[f]) {
        result = true;
      }
    });
    return result;
  },
};
