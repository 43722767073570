<template>
  <div class="radio-atom" v-sgf-theme>
    <label class="radio-atom__label">{{ label }}</label>
    <div class="radio-atom__layout" :class="layoutClasses">
      <div class="radio-atom__layout__option" :class="optionClasses" v-for="(option, index) in options" :key="index + group">
        <input class="radio-atom__layout__option__input" type="radio" :name="group" :value="option.value" @input="onInput" :checked="value === option.value" />
        <span class="radio-atom__layout__option__option">{{ option.option }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    options: Array,
    value: [String, Boolean],
    group: String,
    mode: {
      type: String,
      default: 'vertical',
    },
  },
  emits: ['input'],
  computed: {
    layoutClasses() {
      return {
        'radio-atom__layout--horizontal': this.mode === 'horizontal',
      };
    },
    optionClasses() {
      return {
        'radio-atom__layout__option--horizontal': this.mode === 'horizontal',
      };
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>

<style scoped>
.sgf-ace .radio-atom__label {
  display: block;
  border-left: 2px solid var(--sgf-c-primary);
  margin-bottom: 4px;
  padding-left: 16px;
  line-height: 1.7em;
  font-weight: bold;
  font-size: 0.8em;
}
.sgf-ace .radio-atom__layout--horizontal {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.sgf-ace .radio-atom__layout__option {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
}
.sgf-ace .radio-atom__layout__option:not(.radio-atom__layout__option--horizontal):last-child {
  padding-bottom: 0;
}
.sgf-ace .radio-atom__layout__option__input {
  margin: 0;
  appearance: none;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 16px;
  box-shadow: inset 0 0 0 1px var(--sgf-c-text);
  cursor: pointer;
}
.sgf-ace .radio-atom__layout__option__input:hover {
  background-color: var(--sgf-c-secondary-gray);
}
.sgf-ace .radio-atom__layout__option__input:checked {
  box-shadow: inset 0 0 0 4px var(--sgf-c-text);
}
.sgf-ace .radio-atom__layout__option__input:active {
  box-shadow: inset 0 0 0 1px var(--sgf-c-text), 0 0 0 2px white, 0 0 0 4px var(--sgf-c-text);
}
.sgf-ace .radio-atom__layout__option__input:active:checked {
  box-shadow: inset 0 0 0 4px var(--sgf-c-text), 0 0 0 2px white, 0 0 0 4px var(--sgf-c-text);
}
.sgf-ace .radio-atom__layout__option__option {
  display: inline-block;
  margin-left: 4px;
  line-height: 16px;
}
</style>
