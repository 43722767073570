import types from './types';
import Api from '../api';
const api = new Api();
import payloadHelper from '../helpers/payloadHelper';
export default {
  [types.actions.INITIALIZE]: async (context, configuration) => {
    context.commit(types.mutations.SET_FIELD, { field: 'configuration', value: configuration });
    context.commit(types.mutations.SET_FIELD, { field: 'apiRoot', value: process.env.VUE_APP_WIDGET_APIENDPOINT });
    let memberships = await api.getMemberships(context, configuration.clubId);
    context.commit(types.mutations.SET_FIELD, { field: 'memberships', value: memberships.filter((x) => x.fees.length > 0) });
  },
  [types.actions.SET_FIELD]: (context, fieldValue) => {
    context.commit(types.mutations.SET_FIELD, fieldValue);
  },
  [types.actions.SET_PERSON_FIELD]: (context, fieldValue) => {
    if (fieldValue.field === 'hasGolfId' && fieldValue.value !== context.state.hasGolfId) {
      context.commit(types.mutations.CLEANUP);
    }
    context.commit(types.mutations.SET_PERSON_FIELD, fieldValue);
  },
  [types.actions.SUBMIT]: (context) => {
    context.commit(types.mutations.SET_FIELD, { field: 'hasSubmitted', value: true });
    if (context.getters[types.getters.VALIDATION].length > 0) {
      return;
    }
    if (!context.state.persons.member.hasGolfId) {
      context.dispatch(types.actions.SUBMIT_NEW_MEMBER);
      return;
    }
    if (!context.state.persons.member.verificationEmailSent) {
      context.commit(types.mutations.SET_PERSON_FIELD, { field: 'verificationEmailSent', value: true, personType: 'member' });
      context.dispatch(types.actions.SUBMIT_VERIFICATION);
      return;
    } else {
      context.dispatch(types.actions.SUBMIT_NEW_MEMBER);
      return;
    }
  },
  [types.actions.SUBMIT_VERIFICATION]: async (context) => {
    let { configuration } = context.state;
    let { golfId, verificationEmail } = context.state.persons.member;
    context.commit(types.mutations.SET_FIELD, { field: 'loader', value: 'Skickar verifikationsmail,' });
    try {
      await api.postVerificationEmail(context, { golfId: golfId, email: verificationEmail, organizationId: configuration.clubId });
      context.commit(types.mutations.SET_FIELD, { field: 'loader', value: undefined });
    } catch {
      context.commit(types.mutations.SET_FIELD, { field: 'loader', value: undefined });
      context.commit(types.mutations.SET_PERSON_FIELD, { field: 'verificationEmailSent', value: false, personType: 'member' });
    }
  },
  [types.actions.SUBMIT_NEW_MEMBER]: async (context) => {
    let payload = payloadHelper.registrationPayload(context.state);
    context.commit(types.mutations.SET_FIELD, { field: 'loader', value: 'Registrerar medlemsansökan,' });
    await api.postRegistration(context, payload);
    context.commit(types.mutations.SET_FIELD, { field: 'loader', value: undefined });
    context.commit(types.mutations.SET_FIELD, { field: 'registered', value: true });
  },
  [types.actions.CLEANUP]: (context) => {
    context.commit(types.mutations.CLEANUP);
    context.commit(types.mutations.CLEANUP_PERSON, 'member');
    context.commit(types.mutations.CLEANUP_PERSON, 'caregiver1');
    context.commit(types.mutations.CLEANUP_PERSON, 'caregiver2');
  },
  [types.actions.SET_ERROR]: (context, error) => {
    context.commit(types.mutations.SET_FIELD, { field: 'error', value: error });
    context.commit(types.mutations.SET_FIELD, { field: 'loader', value: undefined });
  },
};
