import ApiBase from '../../widget-common/src/ApiBase';

class Api extends ApiBase {
  async postVerificationEmail(context, payload) {
    await this._fetch(context, `${context.state.apiRoot}/widget/temporaryRegistrationKey`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  async postRegistration(context, payload) {
    await this._fetch(context, `${context.state.apiRoot}/widget/RegisterMembership`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  async getMemberships(context, clubId) {
    return await this._fetch(context, `${context.state.apiRoot}/widget/GetMembershipTypes?OrganizationID=${clubId}`, { method: 'GET' });
  }
}
export default Api;
