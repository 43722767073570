import types from './types';
export default {
  [types.mutations.SET_FIELD]: (state, fieldValue) => {
    state[fieldValue.field] = fieldValue.value;
  },
  [types.mutations.SET_PERSON_FIELD]: (state, fieldValue) => {
    let { field, value, personType } = fieldValue;
    state.persons[personType][field] = value;
  },
  [types.mutations.CLEANUP]: (state) => {
    state.hasSubmitted = false;
    state.registered = false;
    state.recaptchaToken = undefined;
  },
  [types.mutations.CLEANUP_PERSON]: (state, person) => {
    state.persons[person].ssn = '';
    state.persons[person].firstName = '';
    state.persons[person].lastName = '';
    state.persons[person].address = '';
    state.persons[person].postalCode = '';
    state.persons[person].postalAddress = '';
    state.persons[person].phone = '';
    state.persons[person].mobile = '';
    state.persons[person].email = '';
    state.persons[person].confirmEmail = '';
    state.persons[person].golfId = '';
    state.persons[person].verificationEmail = '';
    state.persons[person].verificationEmailSent = false;
    state.persons[person].verificationCode = '';
  },
};
