class ApiBase {
  async _fetch(context, url, options) {
    let response = await fetch(url, options);
    if (response.ok) {
      if (response.status === 200) {
        return await response.json();
      }
      return;
    }
    var body = await response.text();
    let errorMessage = '';
    if (body) {
      errorMessage = JSON.parse(body);
    }
    context.dispatch('setError', errorMessage.technicalMessage);
    return Promise.reject({
      message: errorMessage,
    });
  }
}

export default ApiBase;
