import { createApp } from 'vue';
import { directives as aceDirectives } from '@sgf/go-ace-ui';
import App from './App.vue';
import themeDirectives from '../../widget-common/src/directives/themeDirective';
import store from './store';

function sgf_wmpa_initialize(config) {
  if (!config) {
    return;
  }

  const app = createApp(App);

  app.config.globalProperties.$widgetConfiguration = config;

  themeDirectives(app, config);

  let element;
  if (config.element) {
    element = config.element;
  } else {
    element = document.getElementById(config.hook);
  }

  element.innerHTML = '';

  app.config.errorHandler = (err) => {
    if (err && !err.code) {
      throw err;
    }
  };

  app.use(store);
  app.use(aceDirectives);
  app.mount(element);
}

window.sgf_wmpa_initialize = sgf_wmpa_initialize;

sgf_wmpa_initialize(window['sgf_wmap'] ? window['sgf_wmap'].config : undefined);
