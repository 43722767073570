<template>
  <BoxAtom label="Medlemskap">
    <div class="membership-view">
      <DropdownAtom label="Välj en medlemskategori" :value="selectedMembership" :options="membershipOptions" @input="setField({ field: 'selectedMembership', value: $event })" :error="errors['selectedMembership']" />
      <TableAtom v-if="selectedMembership" :columns="columns" :data="data" />
    </div>
  </BoxAtom>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import types from '../../store/types';
import DropdownAtom from '../../../../widget-common/src/components/atoms/DropdownAtom.vue';
import TableAtom from '../../../../widget-common/src/components/atoms/TableAtom.vue';
import BoxAtom from '../../../../widget-common/src/components/atoms/BoxAtom.vue';
export default {
  components: {
    DropdownAtom,
    TableAtom,
    BoxAtom,
  },
  computed: {
    ...mapState(['persons', 'memberships', 'selectedMembership']),
    ...mapGetters({
      validation: types.getters.VALIDATION,
    }),
    errors() {
      return this.validation
        .filter((x) => x.personType === 'member')
        .reduce((pv, cv) => {
          pv[cv.field] = cv.value;
          return pv;
        }, {});
    },
    columns() {
      return [
        {
          key: 'name',
          header: 'Medlemsavgift',
        },
        {
          key: 'valid',
          header: 'Giltighetsdatum',
        },
        {
          key: 'price',
          header: 'Belopp',
          align: 'right',
        },
      ];
    },
    data() {
      return this.memberships
        .find((x) => x.id === this.selectedMembership)
        .fees.map((x) => {
          return {
            name: x.name,
            price: x.price + ' kr',
            valid: x.validFrom.split('T')[0] + ' - ' + x.validTo.split('T')[0],
          };
        });
    },
    membershipOptions() {
      if (!this.memberships) {
        return [];
      }
      let memberships = this.memberships
        .map((x) => {
          return { value: x.id, label: x.name };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label, 'sv');
        });

      return [
        {
          label: 'Välj...',
          value: '',
        },
        ...memberships,
      ];
    },
  },
  methods: {
    ...mapActions({
      setField: types.actions.SET_FIELD,
    }),
  },
};
</script>

<style scoped></style>
